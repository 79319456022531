/* ==========================================================================
   Variables and Mixins
   ========================================================================== */
$font: 'Open Sans Condensed', sans-serif;
$color-body: #fff;
$color-blue: #224696;



/* ==========================================================================
   General Styling
   ========================================================================== */
*, *:before, *:after {
	box-sizing: border-box;
}

body {
	font-family: $font;
	height: 100vh;
	background: url('../img/page-bg.jpg') center center no-repeat;
	background-size: cover;
}

a {
	text-decoration: none;
	color: inherit;
}

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}



/* ==========================================================================
   Main Content
   ========================================================================== */
// page logo
.page-header {
	display: flex;
	justify-content: center;
	padding-top: 30px;
}

.page-header__logo {
	display: block;
}

// page content
.page-content {
	color: $color-body;
	font-size: 24px;
	padding-left: 65px;
	width: 750px;
	max-width: 100%;
}

.page-heading {
	font-size: 2.5em;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.page-tagline {
	font-size: 1.5em;
	text-transform: uppercase;
	margin-bottom: 20px;

	span {
		display: inline-block;
		padding: 5px 10px;
		background: $color-blue;
	}
}

.page-text {
	line-height: 1.2em;
}



/* ==========================================================================
   Responsive
   ========================================================================== */
@media only screen and (min-width: 2000px) {
	
	body {
		background-position: top -50px center;
	}
}

@media only screen and (max-width: 640px) {

	body {
		height: auto;
		padding-bottom: 30px;
	}

	.page-header__logo {
		padding: 30px 20px;
	}

	.page-content {
		font-size: 18px;
		padding: 0 20px;
	}

}
